<div class="gap-in-cover-capture-container">
    <div class="error-message" *ngIf="document.errorMessage">
      {{ document.errorMessage }}
      <button (click)="closeErrorMessage()">
        <span class="material-symbols-outlined"> close </span>
      </button>
    </div>
    <div
      class="customer-confirmation-box"
      *ngIf="document.isSubmitted === false"
    ></div>
    <form [formGroup]="gapInCoverForm" *ngIf="document.isSubmitted === false">
      <div class="form-control">
        <input
          id="lastInsuredDateAux"
          [matDatepicker]="picker"
          placeholder="End date of your last policy"
          mask="d0/M0/0000"
          type="text"
          [style.display]="'none'"
          (dateChange)="datepickerChange($event)"
        />
        <!-- <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle> -->
        <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
        <div>
          <input
            id="lastInsuredDate"
            formControlName="lastInsuredDate"
            placeholder="End date of your last policy"
            mask="d0/M0/0000"
            type="text"
            (focusout)="onChange()"
            (change)="onChange()"
            [dropSpecialCharacters]="false"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
        </div>
      </div>
      <div class="form-control">
        <input
          type="text"
          id="gapReason"
          formControlName="gapReason"
          (change)="onChange()"
          placeholder="What is the reason for the gap in cover?"
          autocomplete="off"
        />
      </div>
      <div class="form-control">
        <div>
          <input
            type="checkbox"
            id="noIncidentsFlag"
            formControlName="noIncidentsFlag"
            (change)="onChange()"
            autocomplete="off"
          />
          <label class="customer-confirmation-box" for="noIncidentsFlag"
            >I confirm that I have not held Motor Insurance since the expiry of my
            last policy noted above and during which period I have not been
            involved in any incidents that may give rise to a claim.</label
          >
        </div>
      </div>
      <div class="buttons" *ngIf="document.isSubmitted === false">
        <button
          class="submit"
          (click)="onSubmit()"
          [disabled]="!document.enableSubmitButton"
        >
          Submit
        </button>
      </div>
    </form>
  
    <app-upload-confirmation
      *ngIf="document.isSubmitted === true"
    ></app-upload-confirmation>
  </div>
  