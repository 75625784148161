<div class="drag-and-drop-container">
    <div class="message-container" *ngIf="msg">
      <span>{{ msg }}</span>
    </div>
  
    <div *ngIf="fileTitle !== ''" class="file-title">{{ fileTitle }}</div>
  
    <div class="selected-file-container" *ngIf="selectedFile && isFileSelected">
      <div
        [style.display]="
          allowMultipleFiles === true && documentUploadedList.length > 1
            ? 'flex'
            : 'none'
        "
        class="documents-uploaded-container"
        *ngFor="let doc of documentUploadedList"
      >
        <!-- <div *ngIf="doc.name.split('.').pop() !== 'pdf'"> -->
        <div class="file-uploaded-container">
          <div class="img-preview-container">
            <button
              [disabled]="!enableUploadButton"
              class="btn-add-another"
              (click)="openFileBrowser()"
              *ngIf="doc.name === 'btn'"
            >
              <span class="material-symbols-outlined"> add </span>
              <span>Add Another</span>
            </button>
            <img
              *ngIf="doc.name != 'btn' && doc.name.split('.').pop() !== 'pdf'"
              [src]="doc.url"
              width="150px"
              class="mat-elevation-z8"
            />
  
            <img
              *ngIf="doc.name != 'btn' && doc.name.split('.').pop() === 'pdf'"
              src="assets\images\pdf_icon.svg"
              width="150px"
              class="mat-elevation-z8 pdf-icon"
            />
          </div>
          <div class="try-again-container">
            <a *ngIf="doc.name != 'btn'" (click)="clearFile(doc)">Delete</a>
          </div>
        </div>
      </div>
  
      <div
        [style.display]="
          allowMultipleFiles === false && documentUploadedList.length > 1
            ? 'flex'
            : 'none'
        "
        class="documents-uploaded-container"
        *ngFor="let doc of documentUploadedList"
      >
        <!-- <div *ngIf="doc.name.split('.').pop() !== 'pdf'"> -->
        <div class="file-uploaded-container">
          <div class="img-preview-container" *ngIf="doc.name != 'btn'">
            <img
              *ngIf="doc.name.split('.').pop() !== 'pdf'"
              [src]="doc.url"
              width="150px"
              class="mat-elevation-z8"
            />
            <img
              *ngIf="doc.name.split('.').pop() === 'pdf'"
              src="assets\images\pdf_icon.svg"
              width="150px!important"
              class="mat-elevation-z8 pdf-icon"
            />
          </div>
          <div class="try-again-container">
            <a *ngIf="doc.name != 'btn'" (click)="clearFile(doc)">Delete</a>
          </div>
        </div>
      </div>
    </div>
    <div
      [style.display]="documentUploadedList.length < 2 ? 'flex' : 'none'"
      class="drag-and-drop-box"
      appDragAndDropDirective
      (fileDropped)="onFileDropped($event)"
    >
      <input
        #dragAndDropFileInput
        type="file"
        accept="application/pdf, image/jpeg, image/png, image/jpg"
        (change)="fileBrowseHandler($event.target)"
      />
      <div class="icon">
        <!-- <span class="material-symbols-outlined"> cloud_upload </span> -->
  
        <img
          src="../../../../assets/images/upload_cloud_icon.svg"
          width="80px"
          alt=""
        />
      </div>
      <div class="info">
        <span>Drag and drop here</span>
        <span>or</span>
        <span><strong>browse</strong></span>
      </div>
    </div>
  
    <div
      class="upload-button-box"
      [style.display]="documentUploadedList.length < 2 ? 'flex' : 'none'"
      *ngIf="documentUploadedList.length < 2"
    >
      <!-- <button id="take-picture-btn" (click)="openFileBrowser()">
        Take A Picture
        <img
          src="../../../../assets/images/Make A Change Icon SVG.svg"
          width="35px"
          alt=""
        />
      </button> -->
      <button id="upload-file-btn" (click)="openFileBrowser()">
        Upload A File
        <mat-icon svgIcon="UploadaDocument"></mat-icon>
      </button>
    </div>
  </div>
  