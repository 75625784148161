import { Component, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { MyMonitoringService } from './services/logging.service';
import { Exception, SeverityLevel } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
   title = 'Chill Onboarding';

   constructor(
    private monitoringService: MyMonitoringService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
   
  ) {
    console.log(`v${environment.appVersion}`)
    this.matIconRegistry.addSvgIcon(
      'UploadaDocument',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/UploadaDocument.svg'
      )
    );
  
  }
  ngOnInit() {
    debugger
    this.monitoringService.logTrace('### Log Trace - ONBOARDING ###')
  
    console.log(environment.env);
  }
}
