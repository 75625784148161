import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Document } from '../../../../models/document.model';

@Component({
  selector: 'app-licence-capture',
  templateUrl: './licence-capture.component.html',
  styleUrls: ['./licence-capture.component.scss'],
})
export class LicenceCaptureComponent implements OnInit {
  @Input() isAdditionalDriver?: boolean;
  @Input() document!: Document;
  @Input() progress: number = 0;

  @Output() onSubmitted = new EventEmitter();
  @Output() onFileUploaded = new EventEmitter();
  @Output() onFileCleaned = new EventEmitter();
  @Output() onErrorMessageClosed = new EventEmitter();

  isFrontUploaded: boolean = false;
  isBackUploaded: boolean = false;

  frontFile: any;
  backFile: any;

  instructions: string[] = [
    'We can make out all of the information.',
    'We can see all four corners of the bonus in the image.',
    'Only files with pdf, jpeg, jpg and png extensions are accepted.',
  ];

  ngOnInit(): void {
    if (this.isAdditionalDriver)
      this.instructions.push('you upload the licenses for all named drivers');
  }

  onFrontLicenceUpload(event: any) {
    this.frontFile = event.documentUploadedList;

    this.validateEnablingSubmitButton();
  }

  onBackLicenceUpload(event: any) {
    this.backFile = event.documentUploadedList;

    this.validateEnablingSubmitButton();
  }

  validateEnablingSubmitButton() {
    this.document.enableSubmitButton =
      this.frontFile?.length > 0 && this.backFile?.length > 0;
  }

  onCleanFront() {
    this.frontFile = '';
    this.validateEnablingSubmitButton();
  }

  onCleanBack() {
    this.backFile = '';
    this.validateEnablingSubmitButton();
  }

  // onUploadDocument(event: any) {
  //   this.document.files = event.documentUploadedList;
  //   // this.checkSubmitButtonState();

  //   this.onFileUploaded.emit(this.document);
  // }
  // onCleanDocument(event: any) {
  //   this.document.files = event.documentUploadedList;
  //   // this.checkSubmitButtonState();
  //   this.onFileCleaned.emit(this.document);
  // }
  onSubmittedDocument(event: any) {
    this.document.files[0] = this.frontFile[0];
    this.document.files[1] = this.backFile[0];
    this.onSubmitted.emit(this.document);
  }

  closeErrorMessage() {
    this.onErrorMessageClosed.emit(this.document);
  }
}
