<div class="credit-worthiness-check-capture-container">
    <div class="download-container" *ngIf="document.isSubmitted === false">
      <div class="file-title">Download Your Credit Worthiness</div>
      <div class="download-form">
        <button
          id="btn-download"
          matRipple
          [matRippleCentered]="true"
          [matRippleDisabled]="false"
          [matRippleUnbounded]="false"
          (click)="downloadDocument()"
        >
          <span *ngIf="!isDownloadInProgress">Download</span>
          <span *ngIf="isDownloadInProgress">Downloading...</span>
  
          <span class="material-symbols-outlined" *ngIf="!isDownloadInProgress">
            download
          </span>
        </button>
      </div>
    </div>
  
    <div class="error-message" *ngIf="document.errorMessage">
      {{ document.errorMessage }}
      <button (click)="closeErrorMessage()">
        <span class="material-symbols-outlined"> close </span>
      </button>
    </div>
    <app-generic-document-capture
      *ngIf="document.isSubmitted === false"
      [instructions]="instructions"
      [enableSubmitButton]="document.enableSubmitButton"
      [enableUploadButton]="document.enableUploadButton"
      (onFileUploaded)="onUploadDocument($event)"
      (onCleaned)="onCleanDocument($event)"
      (onSubmitted)="onSubmittedDocument($event)"
    ></app-generic-document-capture>
  
    <app-upload-confirmation
      *ngIf="document.isSubmitted === true"
    ></app-upload-confirmation>
  </div>
  