export const environment = {
    production: false,
    appVersion: require('../../package.json').version + '-dev',
    env: 'd.e.v',
    appInsights:{
      instrumentationKey: '61915865-2615-4b92-a788-3bc5d278ac12'
    },
    chill: {
      portalAddress: 'https://sandbox.chill.ie/',
    }, 
    onBoardingDocuments: {
      address: 'https://uat-onboardingfunctions.azurewebsites.net',
      code: 'bQis9R4yqRXzyXNK4cedm0pPhfdUVJT2Ruq0snafBVIxAzFuR2YM0g==',
    },
    eventStreamAPI: {
      address: 'https://mychillstreamfunctions-uat.azurewebsites.net/',
      code: '8nNJ1VLp3XhVYuG0o04q9kKwZsRepGRmkutlVvMlBU4CAzFusEHKGA==',
    },
    apiManagement: {
      address: 'https://dev-api.chill.ie/mychill/dev/',
      subscriptionKey: 'b1dc8cbb656c41afaf515d719a7a14bf',
    },
    files: {
      creditWorthinessCheck: 'CWC.pdf',
    },
    ingestionAPI:
      'https://ca-api-ingestion.agreeableriver-b9cdfe4c.northeurope.azurecontainerapps.io/',
    onboardingAPI:
      'https://ca-api-onbording.agreeableriver-b9cdfe4c.northeurope.azurecontainerapps.io/',
};
