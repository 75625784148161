<div class="upload-confirmation-container">
    <div class="thanks-container">
      <div class="thanks-message">
        <span
          >Thank you. We will review what you have submitted and will contact you if there are any issues.</span
        >
      </div>
      <div class="thanks-icon">
        <img
          src="../../../../assets/images/Thank You Tick Mark.svg"
          width="150px"
          alt=""
        />
  
        <span>Thank You &nbsp;&nbsp;</span>
      </div>
    </div>
  </div>
  