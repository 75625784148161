import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { DocumentService } from '../../../../services/document.service';

export interface document {
  name: string;
  url: any;
  file: any;
}

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent {
  private documentService = inject(DocumentService)


  @ViewChild('dragAndDropFileInput', { static: false })
  InputFile!: ElementRef;

  @Output() onFileUploaded = new EventEmitter();
  @Output() onError = new EventEmitter();
  @Output() onClean = new EventEmitter();

  @Input() fileTitle?: string;
  @Input() allowMultipleFiles: boolean = true;
  @Input() allowProtectedFileUpload: boolean = false;
  @Input() fileExtensionsAccepted: string[] = [];
  @Input() enableUploadButton: boolean = false;

  UploadedFiles: File[] = [];
  documentUploadedList: document[] = [{ name: 'btn', url: null, file: '' }];

  // url: any;
  msg: string = '';
  selectedFile!: File;
  isFileSelected = false;
  files: any[] = [];

  openFileBrowser() {
    this.InputFile.nativeElement.click();
  }

  /**
   * on file drop handler
   */
  onFileDropped(files: any) {
    // alert('drag and drop selected');
    this.prepareFilesList(files, 'DragAndDrop');
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event: any) {
    if (event.files.length > 0)
      this.prepareFilesList(event.files, 'FileBrowse');
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    // alert('file deleted');
    this.files.splice(index, 1);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>, uploadType: string) {
    this.selectedFile = files[0];
    var mimeType = files[0].type;
    if (
      this.selectedFile.name
        .split('.')
        .pop()
        ?.toString()
        .match('^pdf$|^jpg$|^jpeg$|^png$') == null
    ) {
      this.msg =
        'Only files with pdf, jpeg, jpg and png extensions are accepted';
      return;
    } else {
      if (
        this.selectedFile.name.split('.').pop()?.toString().match('^pdf$') !==
        null
      ) {
        const fd = new FormData();
        fd.append('uploadFile', this.selectedFile, this.selectedFile.name);

        if (this.allowProtectedFileUpload === false) {
          this.documentService.isProtectedPdfFile(fd).subscribe(
            (res) => {
              if (res.isValid) {
                this.isFileSelected = true;
                var reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = (_event) => {
                  this.msg = '';
                  let url = reader.result?.toString();

                  let doc = <document>{
                    name: this.selectedFile.name,
                    url: url,
                    file: this.selectedFile,
                  };

                  this.documentUploadedList.push(doc);

                  this.removeFile();
                  // this.url = reader.result;

                  // if (this.isFileSelected) {
                  this.onFileUploaded.emit({
                    // file: this.selectedFile,
                    documentUploadedList: this.documentUploadedList.filter(
                      (x) => x.name != 'btn'
                    ),
                  });
                  // }
                };
                // this.onFileUploaded.emit({
                //   category: 'D1NCD',
                //   uploadType: 'Camera',
                //   fileType: mimeType,
                // });
              } else {
                this.msg =
                  'Password protected files are not allowed, remove the password from the file and try again.';
                return;
              }
            },
            (error) => {
              this.msg = 'Something went wrong!';
              return;
            }
          );
        }
      } else {
        this.isFileSelected = true;
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          this.msg = '';

          let url = reader.result?.toString();

          let doc = <document>{
            name: this.selectedFile.name,
            url: url,
            file: this.selectedFile,
          };

          this.documentUploadedList.push(doc);
          this.removeFile();

          // this.url = reader.result;

          if (this.isFileSelected) {
            this.onFileUploaded.emit({
              // file: this.selectedFile,
              documentUploadedList: this.documentUploadedList.filter(
                (x) => x.name != 'btn'
              ),
            });
          }
        };
        // this.onFileUploaded.emit({
        //   category: 'D1NCD',
        //   uploadType: uploadType,
        //   fileType: mimeType,
        // });
      }
    }
  }

  clearFile(doc: document) {
    if (this.documentUploadedList.length < 1) this.isFileSelected = false;
    // this.url = '';

    const index = this.documentUploadedList.indexOf(doc, 0);
    this.documentUploadedList.splice(index, 1);

    this.onClean.emit({
      documentUploadedList: this.documentUploadedList.filter(
        (x) => x.name !== 'btn'
      ),
    });
  }

  removeFile() {
    this.InputFile.nativeElement.value = '';
  }
}
