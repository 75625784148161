<div class="footer-container">
    <div class="footer-box">
      <div class="footer-logos">
        <img
          src="assets/images/footer-logo.svg"
          alt="Chill Insurance Logo"
          width="100px"
        />
  
        <!-- <div
          class="trustpilot-widget"
          data-locale="en-IE"
          data-template-id="5419b732fbfb950b10de65e5"
          data-businessunit-id="57eb89ea0000ff0005954a7d"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light"
        >
          <a
            href="https://ie.trustpilot.com/review/chill.ie"
            target="_blank"
            rel="noopener"
            >Trustpilot</a
          >
        </div> -->
        <!-- End TrustBox widget -->
      </div>
  
      <mat-divider class="divider"></mat-divider>
  
      <div class="copyright">
        <div>
          Copyright © Chill Insurance. All Rights Reserved. Chill Insurance Ltd.
          Chill Insurance is regulated by the Central Bank of Ireland. Registered
          number 506021. Registered in the Republic of Ireland.<a
            id="terms-of-business"
            href="{{ chillPortalAddress }}company/legal/terms-of-business/"
            target="_blank"
          >
            Terms of Business,</a
          >
  
          <a
            id="privacy-policy"
            href="{{ chillPortalAddress }}company/legal/privacy-policy/"
            target="_blank"
            >Privacy Policy.</a
          >
        </div>
  
        <!-- OneTrust Cookies Settings button start -->
        <button id="ot-sdk-btn" class="ot-sdk-show-settings">
          Cookie Settings
        </button>
        <!-- OneTrust Cookies Settings button end -->
      </div>
    </div>
  </div>
  