<div class="licence-capture-container">
    <div class="error-message" *ngIf="document.errorMessage">
      {{ document.errorMessage }}
      <button (click)="closeErrorMessage()">
        <span class="material-symbols-outlined"> close </span>
      </button>
    </div>
    <app-drag-and-drop
      *ngIf="document.isSubmitted === false"
      [allowMultipleFiles]="false"
      fileTitle="Front"
      (onFileUploaded)="onFrontLicenceUpload($event)"
      (onClean)="onCleanFront()"
    ></app-drag-and-drop>
  
    <app-generic-document-capture
      *ngIf="document.isSubmitted === false"
      [allowMultipleFiles]="false"
      [instructions]="instructions"
      [enableSubmitButton]="document.enableSubmitButton"
      [enableUploadButton]="document.isSubmitted"
      fileTitle="Back"
      (onFileUploaded)="onBackLicenceUpload($event)"
      (onCleaned)="onCleanBack()"
      (onSubmitted)="onSubmittedDocument($event)"
    ></app-generic-document-capture>
  
    <!-- <mat-progress-bar
      *ngIf="document.uploadProgress > 0 && document.isSubmitted === false"
      class="example-margin"
      [color]="'\#5a2293'"
      [mode]="'determinate'"
      [value]="progress"
      [bufferValue]="25"
    >
    </mat-progress-bar> -->
  
    <app-upload-confirmation
      *ngIf="document.isSubmitted === true"
    ></app-upload-confirmation>
  </div>
  