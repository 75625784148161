import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Document } from '../../../../models/document.model';
import { FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-gap-in-cover-capture',
  templateUrl: './gap-in-cover-capture.component.html',
  styleUrl: './gap-in-cover-capture.component.scss',
  
})
export class GapInCoverCaptureComponent {
  @Input() progress: number = 0;
  @Input() document!: Document;
  @Input() customerName!: string;

  @Output() onChanged = new EventEmitter();
  @Output() onSubmitted = new EventEmitter();
  @Output() onErrorMessageClosed = new EventEmitter();

  constructor(private fb: FormBuilder, private datepipe: DatePipe) {}

  isDisabled: boolean = true;

  gapInCoverForm = this.fb.group({
    lastInsuredDate: [{ value: '', disabled: false }, Validators.required],
    gapReason: ['', Validators.required],
    noIncidentsFlag: ['', [Validators.required, Validators.requiredTrue]],
  });

  onChange() {
    let diaryValidated = this.gapInCoverForm.valid;

    if (diaryValidated) {
      this.document.keyValues?.push({
        key: 'lastInsuredDate',
        value: this.gapInCoverForm?.controls?.lastInsuredDate?.value ?? '',
      });
      this.document.keyValues?.push({
        key: 'gapReason',
        value: this.gapInCoverForm?.controls?.gapReason?.value ?? '',
      });
      this.document.keyValues?.push({
        key: 'noIncidentsFlag',
        value:
          this.gapInCoverForm?.controls?.noIncidentsFlag?.value?.toString() ??
          '',
      });
    }

    this.onChanged.emit({
      isFormValid: diaryValidated,
      document: this.document,
    });
  }

  datepickerChange(event: any) {
    this.gapInCoverForm.controls['lastInsuredDate'].patchValue(
      this.datepipe.transform(event.value, 'dd/MM/yyyy')
    );

    this.onChange();
  }

  onDateChange(event: any) {
    console.log;
  }

  onSubmit() {
    this.onSubmitted.emit(this.document);
  }

  closeErrorMessage() {
    this.onErrorMessageClosed.emit(this.document);
  }
}
