import { Component, inject, OnInit } from '@angular/core';
import { OnboardingPageComponent } from '../../../onboarding/pages/onboarding-page/onboarding-page.component';
import { MatDialog } from '@angular/material/dialog';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss'
})
export class HomePageComponent implements OnInit {
  route = inject(ActivatedRoute)
  router = inject(Router)
  private formBuilderService  = inject(NonNullableFormBuilder)

  protected form = this.formBuilderService.group({
    policyCode: ["", [Validators.required, Validators.maxLength(9)]]
  })
  private activatedRoute = inject(ActivatedRoute);
  private dialog = inject(MatDialog)

  //@Input() policyCode!: string;
  policyCode: string = ""
  utm_source: string = ""
  utm_medium: string = ""
  utm_campaign: string = ""

  ngOnInit(): void {
debugger
    this.policyCode = this.activatedRoute.snapshot.params['policyCode']!
    this.utm_campaign = this.route.snapshot.queryParamMap.get("utm_campaign")!
    this.utm_source = this.route.snapshot.queryParamMap.get("utm_source")!
    this.utm_medium = this.route.snapshot.queryParamMap.get("utm_medium")!
console.log(this.policyCode);
    if(this.policyCode?.length != 9){
      this.router.navigate(["/400"])
    }
    else{
      this.openOnboardingPage()
    }
   
   

    console.log("capturing querystring parameters")
  }

  openOnboardingPage() {
    // this.onButtonClicked.emit({
    //   policy: this.policy,
    //   action: 'Upload a Document',
    // });

    //this.isOnboardingVisible = true;

    const dialogRef = this.dialog.open(OnboardingPageComponent, {
      height: 'calc(100vh - 35px)',
      position: { right: '0', top: '35px' },
      maxWidth: '100dvw',
      width: '100%',
      hasBackdrop: true,
      disableClose: true,
      data: {
        policyCode: this.policyCode?.toUpperCase(),
        utm_campaign: this.utm_campaign,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
}