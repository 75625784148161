import { Component, EventEmitter, inject, Input, Output } from '@angular/core';

import { Document } from '../../../../models/document.model';
import { OpenOnboardingService } from '../../../../services/open-onboarding.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-credit-worthiness-check-capture',
  templateUrl: './credit-worthiness-check-capture.component.html',
  styleUrl: './credit-worthiness-check-capture.component.scss'
})
export class CreditWorthinessCheckCaptureComponent {
  private onboardingService = inject(OpenOnboardingService);

  @Input() progress: number = 0;
  @Input() document!: Document;

  @Output() onSubmitted = new EventEmitter();
  @Output() onFileUploaded = new EventEmitter();
  @Output() onFileCleaned = new EventEmitter();
  @Output() onErrorMessageClosed = new EventEmitter();

  isDownloadInProgress: boolean = false;

  instructions: string[] = [];

  ngOnInit(): void {
   
    this.instructions.push(
      ...[
        `Your client code <strong>${this.document.diary?.policyCode.substring(
          0,
          6
        )} </strong> and name are input on the top of both pages.`,
        'Nothing can be left blank; everything needs to be completed even if not applicable.',
        'This document is required in order for Close Brothers Premium Finance to authorise your finance agreement.',
      ]
    );
  }

  onUploadDocument(event: any) {
    this.document.files = event.documentUploadedList;
    // this.checkSubmitButtonState();

    this.onFileUploaded.emit(this.document);
  }
  onCleanDocument(event: any) {
    this.document.files = event.documentUploadedList;
    // this.checkSubmitButtonState();
    this.onFileCleaned.emit(this.document);
  }
  onSubmittedDocument(event: any) {
    this.onSubmitted.emit(this.document);
  }

  closeErrorMessage() {
    this.onErrorMessageClosed.emit(this.document);
  }

  downloadDocument() {
    this.isDownloadInProgress = true;

    this.onboardingService
      .getFileToDownload(environment.files.creditWorthinessCheck)
      .subscribe({
        next: (res: any) => {
    
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(res);
          a.href = objectUrl;
          a.download = `CWC${this.document.diary?.policyCode.substring(
            0,
            6
          )}.pdf`;
          a.click();
          URL.revokeObjectURL(objectUrl);

          this.isDownloadInProgress = false;
        },
      });

    // this.relayService
    // .getRetrieveDocumentFile(obj.portfolioId, obj.policyId, obj.documentId)
    // .subscribe(
    //   (res) => {
    //     const a = document.createElement('a');
    //     const objectUrl = URL.createObjectURL(res);
    //     a.href = objectUrl;
    //     a.download = `${
    //       this.data.policy.policyCode
    //     }_${digitalDocRef.title.replaceAll(' ', '')}.pdf`;
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);
    //     // this.loadingDownloadNBPack = false;
    //     digitalDocRef.isDownloadInProgress = false;
    //     btn.isDownloadInProgress = false;
    //     event.downloadedSuccessfully = 'Y';
    //     this.eventService.genericEvent(event);
    //   },
    //   (error) => {
    //     digitalDocRef.errorMessage = error;
    //     digitalDocRef.isDownloadInProgress = false;
    //     btn.isDownloadInProgress = false;
    //     // console.log('NBPack creation error ' + error);
    //     event.downloadedSuccessfully = 'N';
    //     event.downloadError = error.message;
    //     this.eventService.genericEvent(event);
    //   }
    // );
  }
}
