<div class="onboarding-page-container">
    <!-- <button id="closeDialog" (click)="closeDialog()">
      <img src="../assets/images/x_circle_icon.svg" alt="" />
    </button> -->
  
    <!-- Policy Header -->
    <!-- <div id="policy-detail-header">
     
      
      <mat-divider></mat-divider>
    </div>  -->
  
    <div class="main-container" *ngIf="!loading">
      <div id="policy-detail-header" >
        
        <h1 >Policy Code: <strong>{{this.data.policyCode}}</strong></h1>

        <mat-divider></mat-divider>
      </div>
  
      <div
        class="policy-document-container"
        *ngFor="let doc of documentsDiary$ | orderBy : 'sequence' : 'asc'"
      >
        <div class="policy-document-box">
          <div
            class="policy-document-header"
            [ngClass]="
              doc.diary.id === expandedControl ? 'expanded' : 'collapsed'
            "
            (click)="expand(doc)"
          >
            <div class="document-title">
              {{ doc.title }}
  
              <img
                id="uploaded-icon"
                *ngIf="doc.isSubmitted"
                src="../../../../assets/images/check-confirmation.svg"
                width="40px"
                alt=""
              />
            </div>
            <div class="document-info">
              <img
                id="info-icon"
                *ngIf="!doc.isSubmitted"
                src="../../../../assets/images/info-circle.svg"
                width="30px"
                alt=""
              />
              <label id="info" *ngIf="!doc.isSubmitted">{{ doc.info }}</label>
            </div>
            <div class="arrow">
              <span class="material-symbols-outlined">
                {{
                  doc.diary.id === expandedControl ? "expand_less" : "expand_more"
                }}
              </span>
            </div>
          </div>
          <div
            class="policy-document-content"
            [ngSwitch]="doc.name"
            [ngClass]="{
              show: doc.diary.id === expandedControl,
              hide: doc.diary.id !== expandedControl
            }"
          >
            <div class="content-body" [id]="doc.diary.id">
              
              <app-iban-capture
                *ngSwitchCase="'iban'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                
                (onSubmitted)="submitTextDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-iban-capture>
             <app-no-claim-bonus-capture
                *ngSwitchCase="'ncb'"
                [document]="doc"
                [progress]="doc.uploadProgress"
               
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-no-claim-bonus-capture>
               <app-licence-capture
                *ngSwitchCase="'mainLicence'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-licence-capture>
              <app-licence-capture
                *ngSwitchCase="'aditionalDriver'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-licence-capture>
             <app-driving-experience-capture
                *ngSwitchCase="'drivingExperience'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-driving-experience-capture>
            <app-gap-in-cover-capture
                *ngSwitchCase="'gapInCover'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onChanged)="onMultiTextDocumentChange($event)"
                (onSubmitted)="submitTextDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-gap-in-cover-capture>
                <app-proposal-form-capture
                *ngSwitchCase="'proposalForm'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-proposal-form-capture>
              <app-nct-certificate-capture
                *ngSwitchCase="'nct'"
                [document]="doc"
                [progress]="doc.uploadProgress"
               
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-nct-certificate-capture>
             <app-engineer-report-capture
                *ngSwitchCase="'engineerReport'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-engineer-report-capture>
              <app-irish-registration-capture
                *ngSwitchCase="'irishRegistration'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                
                (onChanged)="onTextDocumentChange($event)"
                (onSubmitted)="submitTextDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-irish-registration-capture>
              <app-valuation-specified-item-capture
                *ngSwitchCase="'valuationOfSpecifiedItem'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-valuation-specified-item-capture>
               <app-direct-debit-mandate-capture
                *ngSwitchCase="'directDebitMandate'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-direct-debit-mandate-capture>
              <app-pps-proof-capture
                *ngSwitchCase="'ppsProof'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-pps-proof-capture>
               <app-identification-proof-capture
                *ngSwitchCase="'identificationProof'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-identification-proof-capture>
            <app-commercial-ddm-capture
                *ngSwitchCase="'CommercialDDM'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-commercial-ddm-capture>
             <app-digital-journey-capture
                *ngSwitchCase="'digitalJourney'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-digital-journey-capture>
              <app-certificate-of-incorporation-capture
                *ngSwitchCase="'certitifateOfIncorporation'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-certificate-of-incorporation-capture>
              <app-vlc-capture
                *ngSwitchCase="'vlc'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-vlc-capture>
              <app-proof-of-business-address-capture
                *ngSwitchCase="'proofOfBusinessAddress'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-proof-of-business-address-capture>
               <app-tax-registration-certificate-capture
                *ngSwitchCase="'taxRegistrationCertificate'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-tax-registration-certificate-capture>
              <app-credit-worthiness-check-capture
                *ngSwitchCase="'creditWorthinessCheck'"
                [document]="doc"
                [progress]="doc.uploadProgress"
                (onFileCleaned)="onFileCleaned($event)"
                (onFileUploaded)="onFileUploaded($event)"
                (onSubmitted)="submitDocument($event)"
                (onErrorMessageClosed)="errorMessageClosed($event)"
              ></app-credit-worthiness-check-capture>
            </div>
          </div>
        </div>
        <div class="progress-container">
          <mat-progress-bar
            *ngIf="doc.uploadProgress > 0 && doc.isSubmitted === false"
            class="example-margin"
            color="red"
            [mode]="'determinate'"
            [value]="doc.uploadProgress"
            [bufferValue]="25"
          >
          </mat-progress-bar
          ><span *ngIf="doc.uploadProgress > 0 && doc.isSubmitted === false"
            >{{ doc.uploadProgress }}%</span
          >
        </div>
      </div>
  
      <div class="no-docs-need-container" *ngIf="documentsDiary$.length == 0">
        <span>
          We don't need any documents from you right now. We will be in touch if
          we need anything from you.
        </span>
  
        <!-- <div class="action-container">
          <a
            mat-button
            class="submit"
            href="{{ chillPortalAddress }}chat/"
            target="_blank"
          >
            Chat to an agent</a
          >
        </div> -->
        <!-- </div>
      </div> -->
      </div>
    </div>
    <div class="spinner-container">
      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
  </div>
  