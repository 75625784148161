import { Component } from '@angular/core';

@Component({
  selector: 'app-bad-request-page',
  templateUrl: './bad-request-page.component.html',
  styleUrl: './bad-request-page.component.scss'
})
export class BadRequestPageComponent {

}
