<div class="iban-capture-container">
    <div class="error-message" *ngIf="document.errorMessage">
      {{ document.errorMessage }}
      <button (click)="closeErrorMessage()">
        <span class="material-symbols-outlined"> close </span>
      </button>
    </div>
    <!-- <div class="info-container" *ngIf="document.isSubmitted === false">
      If you have your IBAN handy, you can enter it and we will start setting up
      your payment.
    </div> -->
    <form [formGroup]="ibanForm" *ngIf="document.isSubmitted === false">
      <div>
        <input
          formControlName="iban"
          (change)="onChange()"
          maxlength="31"
          type="text"
          id="iban"
          autocomplete="off"
          placeholder="Type Your IBAN"
          [ngClass]="{ 'invalid-iban': isInvalidIban }"
        />
        <span class="iban-message-validation" *ngIf="isInvalidIban"
          >Please check the IBAN again</span
        >
      </div>
      <div id="instruction-container" *ngIf="instructions">
        <strong>Just make sure</strong><br />
        <span *ngFor="let instruction of instructions"
          >- {{ instruction }} <br
        /></span>
      </div>
      <!-- <div class="confirmation-container">
        <span>Tick the box if all that is correct</span
        ><input
          type="checkbox"
          name="confirmation-check"
          id="confirmation-check"
        />
      </div> -->
      <div class="buttons">
        <button
          class="submit"
          (click)="onSubmit()"
          [disabled]="!document.enableSubmitButton"
        >
          Submit
        </button>
      </div>
    </form>
    <app-upload-confirmation
      *ngIf="document.isSubmitted === true"
    ></app-upload-confirmation>
  </div>
  