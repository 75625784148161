import { Component, EventEmitter, Input, Output } from '@angular/core';

import {Document} from '../../../../models/document.model'

@Component({
  selector: 'app-commercial-ddm-capture',
  templateUrl: './commercial-ddm-capture.component.html',
  styleUrl: './commercial-ddm-capture.component.scss'
})
export class CommercialDdmCaptureComponent {
  @Input() progress: number = 0;
  @Input() document!: Document;

  @Output() onSubmitted = new EventEmitter();
  @Output() onFileUploaded = new EventEmitter();
  @Output() onFileCleaned = new EventEmitter();
  @Output() onErrorMessageClosed = new EventEmitter();

  instructions: string[] = [
    'We can make out all of the information.',
    'We can see all four corners of the document in the image.',
    'Only the policy holder is required to sign under the heading “Signature of Customer”.',
    'The "Signature of Bank" section must be left blank.',
    'The mandate itself must be completed and signed by the person whose bank details are provided.',
    'If the account is in one name only, please print that person’s name in the first “Your Name” box and ask them to sign in the “Signature(s)” space.',
    'If the account is a joint account, please ensure both names are printed, in the sections marked “Your Name(s)” and signed by both account holders in the “Signature(s)” space.',
    'Only files with pdf, jpeg, jpg and png extensions are accepted.',
  ];

  onUploadDocument(event: any) {
    this.document.files = event.documentUploadedList;
    // this.checkSubmitButtonState();

    this.onFileUploaded.emit(this.document);
  }
  onCleanDocument(event: any) {
    this.document.files = event.documentUploadedList;
    // this.checkSubmitButtonState();
    this.onFileCleaned.emit(this.document);
  }
  onSubmittedDocument(event: any) {
    this.onSubmitted.emit(this.document);
  }

  closeErrorMessage() {
    this.onErrorMessageClosed.emit(this.document);
  }
}
