import { Component, EventEmitter, Input, Output } from '@angular/core';

import { validateIBAN } from 'ngx-iban-validator';
import { Document } from '../../../../models/document.model';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-iban-capture',
  templateUrl: './iban-capture.component.html',
  styleUrl: './iban-capture.component.scss'
})
export class IbanCaptureComponent {
  @Input() progress: number = 0;
  @Input() document!: Document;

  @Output() onChanged = new EventEmitter();
  @Output() onSubmitted = new EventEmitter();
  @Output() onErrorMessageClosed = new EventEmitter();

  instructions: string[] = [
    'It is a current account.',
    'If a joint account only one signature is needed on it.',
    'The insurer is the account holder.',
  ];

  irishIbanPattern =
    '^((NO)\\d{13}|(BE)\\d{14}|(NL)\\d{2}[a-zA-Z]{4}\\d{10}|(SI)\\d{17}|(DK|FI)\\d{16}|(AT|EE|LT|LU)\\d{18}|(HR|LI|CH)\\d{19}|(LV)\\d{2}[a-zA-Z]{4}\\d{13}|(BG|IE|GB)\\d{2}[a-zA-Z]{4}\\d{14}|(DE|VA)\\d{20}|(GI)\\d{2}[a-zA-Z]{4}\\d{15}|(AD|CZ|SK|ES|SE)\\d{22}|(RO)\\d{2}[a-zA-Z]{4}\\d{16}|(PT)\\d{23}|(IS)\\d{24}|(FR|GR|MC)\\d{25}|(IT|SM)\\d{2}[a-zA-Z]{1}\\d{22}|(CY|HU|PL)\\d{26}|(MT)\\d{2}[a-zA-Z]{4}\\d{23})$';

  ibanTester = new RegExp(this.irishIbanPattern, 'i');

  disableSubmitButton: boolean | undefined = true;
  isDocUploaded: boolean | undefined = false;
  isInvalidIban: boolean | undefined = false;

  //////

  constructor(private fb: FormBuilder) {}

  ibanForm = this.fb.group({
    iban: [null, Validators.required],
  });

  onChange() {
    // this.document.value = this.ibanForm.controls.iban.value;

    let iban = this.ibanForm.controls.iban.value ?? '';

    this.onChanged.emit(this.document);

    let isValid = this.ibanTester.test(iban.replaceAll(' ', '').trim());
    var ibanIsInvalid: boolean | undefined = true;

    if (isValid) {
      ibanIsInvalid = validateIBAN({
        value: iban.replaceAll(' ', '').trim(),
      })?.ibanInvalid;

      console.log(ibanIsInvalid);
    }
    this.document.value = iban;
    this.document.keyValues?.push({ key: 'iban', value: iban });
    console.log('IBAN ' + iban + '- INVALID: ' + ibanIsInvalid);
    this.disableSubmitButton = ibanIsInvalid;

    this.isInvalidIban = ibanIsInvalid;
    this.document.enableSubmitButton = !ibanIsInvalid;
  }

  onSubmit() {
    this.onSubmitted.emit(this.document);
  }

  closeErrorMessage() {
    this.onErrorMessageClosed.emit(this.document);
  }
}
