import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Diary } from '../models/diary.model';
import { DocumentText } from '../models/documentText.model';

@Injectable({
  providedIn: 'root'
})
export class OpenOnboardingService {
  private httpClient = inject(HttpClient)


  getDiaryByPolicyCode(policyCode: string): Observable<Diary[]> {
    let apiAddress = environment.apiManagement.address;

    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    return this.httpClient.get<Diary[]>(
      `${apiAddress}api/OpenOnboarding/GetDiaryByPolicyCode?PolicyCode=${policyCode}`,
      { headers: customHeaders }
    );
  }

  uploadDocuments(fd: FormData): Observable<any> {
    let apiAddress = environment.apiManagement.address;

    let customHeaders = new HttpHeaders()
      // .set('Content-Type', 'multipart/form-data')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    return this.httpClient.post(
      // `${apiAddress}api/documents/upload?subscription-key=${environment.apiManagement.subscriptionKey}`,
      `${apiAddress}api/OpenOnboarding/upload`,
      fd,
      {
        headers: customHeaders,
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  uploadTextFile(model: DocumentText) {
    let apiAddress = environment.apiManagement.address;

    let customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );
    // customHeaders.set('Accept', 'application/json');

    return this.httpClient.post(`${apiAddress}api/OpenOnboarding/uploadTextFile`, model, {
      reportProgress: true,
      observe: 'events',
      headers: customHeaders,
    });
  }

  getFileToDownload(filename: string) {
    let apiAddress = environment.apiManagement.address;

    let customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );
    // customHeaders.set('Accept', 'application/json');

    return this.httpClient.get(
      `${apiAddress}api/OpenOnboarding/getFileToDownload?fileName=${filename}`,
      {
        headers: customHeaders,
        responseType: 'blob',
      }
    );
  }

}
