import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private httpClient = inject(HttpClient)
  

  isProtectedPdfFile(fd: FormData): Observable<any> {
    let customHeaders = new HttpHeaders();

    customHeaders.set('Content-Type', 'multipart/form-data');
    customHeaders.set('Accept', 'application/json');

    return this.httpClient.post(
      `${environment.onBoardingDocuments.address}/api/IsPdfFileProtected?code=${environment.onBoardingDocuments.code}`,
      fd
    );
  }

  uploadDocuments(fd: FormData): Observable<any> {
    let customHeaders = new HttpHeaders();

    customHeaders.set('Content-Type', 'multipart/form-data');
    customHeaders.set('Accept', 'application/json');

    return this.httpClient.post('http://localhost:5129/document/upload', fd, {
      headers: customHeaders,
      reportProgress: true,
      observe: 'events',
    });

    // .pipe(
    //   retryWhen((errors) => errors.pipe(delay(1000), take(5))),
    //   first((v) => true),
    //   catchError((error) => {
    //     // console.log('triggering the error after the last unsuccessful retry');
    //     return throwError(error);
    //   })
    // );
  }

}
