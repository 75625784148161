<div class="irish-registration-capture-container">
    <div class="error-message" *ngIf="document.errorMessage">
      {{ document.errorMessage }}
      <button (click)="closeErrorMessage()">
        <span class="material-symbols-outlined"> close </span>
      </button>
    </div>
    <form
      [formGroup]="irishRegistrationForm"
      *ngIf="document.isSubmitted === false"
    >
      <div>
        <input
          type="text"
          id="irish-registration"
          formControlName="registrationNumber"
          (change)="onChange()"
          placeholder="Please enter your Irish Registration Number"
          autocomplete="off"
        />
      </div>
      <div class="buttons" *ngIf="document.isSubmitted === false">
        <button
          (click)="onSubmit()"
          [disabled]="!document.enableSubmitButton"
          class="submit"
        >
          Submit
        </button>
      </div>
    </form>
    <app-upload-confirmation
      *ngIf="document.isSubmitted === true"
    ></app-upload-confirmation>
  </div>
  