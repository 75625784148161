<div class="engineer-report-capture container">
    <div class="error-message" *ngIf="document.errorMessage">
      {{ document.errorMessage }}
      <button (click)="closeErrorMessage()">
        <span class="material-symbols-outlined"> close </span>
      </button>
    </div>
    <app-generic-document-capture
      *ngIf="document.isSubmitted === false"
      [instructions]="instructions"
      [enableSubmitButton]="document.enableSubmitButton"
      [enableUploadButton]="document.enableUploadButton"
      (onFileUploaded)="onUploadDocument($event)"
      (onCleaned)="onCleanDocument($event)"
      (onSubmitted)="onSubmittedDocument($event)"
    ></app-generic-document-capture>
  
    <app-upload-confirmation
      *ngIf="document.isSubmitted === true"
    ></app-upload-confirmation>
  </div>
  