import { Component, Inject, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { Diary } from '../../../../models/diary.model';
import { OpenOnboardingService } from '../../../../services/open-onboarding.service';
import { Document } from '../../../../models/document.model';
import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { DocumentText } from '../../../../models/documentText.model';
import { DocumentUploaded } from '../../../../models/documentUploaded.model';
import { OnboardingPageLandingEvent } from '../../../../models/events/onboardingPageLandingEvent.model';
import { EventsService } from '../../../../services/events.service';
import { OnboardingPageSubmitButtonClickedEvent } from '../../../../models/events/onboardingPageSubmitButtonClickedEvent.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding-page',
  templateUrl: './onboarding-page.component.html',
  styleUrl: './onboarding-page.component.scss'
})
export class OnboardingPageComponent  implements OnInit {

  private openOnboardingService = inject(OpenOnboardingService)
  private eventService = inject(EventsService)
  router = inject(Router)
  dialog = inject(MatDialog)

  
  chillPortalAddress: string = environment.chill.portalAddress;
  collapsed: boolean = false;
  diaries: Diary[] = [];
  documentList: any[] = [];
  progress: number = 0;
  documentsDiary$: Document[] = [];
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { policyCode: string, utm_campaign: string, utm_source: string, utm_medium: string },
  
    //private documentService: DocumentService,
   // private eventStreamService: EventStreamingService,
   // private ingestionService: IngestionAPIService,
    //private onboardingService: OnboardingService,
    //private authService: AuthService,
   // public dialog: MatDialog,
    //public http: HttpClient
  ) {}

  ngOnInit(): void {

    console.log("Onboaring Page Loading")
    console.log("######################")



    this.loading = true;

    this.openOnboardingService
      .getDiaryByPolicyCode(this.data.policyCode)
      .subscribe(
        (res) => {
          this.diaries = res;

          this.diaries.forEach((d, i) => {
            var document = this.documents.filter(
              (x) => x.diaryCode == d.diaryTypeId
            );

            let newDocument = { ...document[0] }; // Copy object without copy the reference

            if (!d.status.approved) {
              if (d.status.diaryOpen) {
                if (!d.status.uploaded && !d.status.approved) {
                  newDocument.isSubmitted = false;
                } else if (d.status.uploaded && !d.status.approved) {
                  newDocument.isSubmitted = true;
                }

                newDocument.diary = d;
                if (d.diaryTypeId == '2F') {
                  newDocument.title = d.driver.name + ' Licence';
                }
              }

              this.documentsDiary$.push(newDocument);
            }
          });

          console.log('ONBOARDING COMPONENTS REQUIRED');
          console.warn(this.documentsDiary$);
          this.loading = false;
          this.landingPageEvent();
        },
        (error) => {
          console.error(error);
          this.loading = false;
          this.landingPageEvent();

          error.status == 404? this.router.navigate(["/404"]): this.router.navigate(["/400"]);
          
          this.closeDialog()
        }
      );
  }

  expandedControl = 'iban';
  progressBarColor = '#28b9d6!important';
  documents: Document[] = [
    {
      name: 'iban',
      title: 'IBAN',
      diaryCode: '2T',
      type: 'text',
      value: '',
      keyValues: [],
      sequence: 0,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'International Bank Account number is required to set up your direct debits.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'ncb',
      title: 'No Claims Bonus',
      diaryCode: '2C',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 1,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'Original, Proof of No Claims Bonus. This can be obtained from your previous insurer and will have been included with your renewal documents.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'mainLicence',
      title: 'Your Licence',
      diaryCode: '2E',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 2,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: "Copy of your driver's licence, front and back.",
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'aditionalDriver',
      title: 'Additional Driver',
      diaryCode: '2F',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 3,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: "Copy the named driver's licence, front and back.",
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'drivingExperience',
      title: 'Letter(s) of Driving Experience',
      diaryCode: '2D',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 4,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'This can be sourced from your previous insurer(s). We require proof of claims free driving experience to be consecutive and up to date. There can be no gaps between the cover. The letters should include the dates of cover and that no claims were made against the driver while cover was in-force',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'gapInCover',
      title: 'Gap In Cover Letter',
      diaryCode: '2I',
      type: 'text',
      value: '',
      keyValues: [],
      sequence: 5,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'It must include the date of cancellation or expiry of your previous policy and confirm that you have not been involved in any accidents or claims since then. The reason for the gap in cover must be stated e.g. car sold, emigration.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'proposalForm',
      title: 'Proposal Form',
      diaryCode: '2A',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 6,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'Document to be completed, signed and returned to Chill. This document will have been included with your initial insurance pack we sent to you.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'nct',
      title: 'NCT Certificate',
      diaryCode: '2J',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 7,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'An image of the NCT to show that the car is road worthy.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'engineerReport',
      title: 'Engineers Report',
      diaryCode: '2L',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 8,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'An image to show that the car is road worthy or that repairs have been carried out.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'irishRegistration',
      title: 'Irish Registration',
      diaryCode: '2N',
      type: 'text',
      value: '',
      keyValues: [],
      sequence: 9,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'If a car has been imported we need the Irish registration number once it has been provided to you.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'valuationOfSpecifiedItem',
      title: 'Valuation of Specified Item(s)',
      diaryCode: '2H',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 10,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'A copy of a valuation showing a description of the specified item(s) item and the value.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'directDebitMandate',
      diaryCode: '2B',
      type: 'file',
      value: '',
      keyValues: [],
      title: 'Direct Debit Mandate',
      sequence: 10,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'Document to be completed, signed and returned to Chill in order to set up your direct debits. This document will have been included with your initial insurance pack we sent to you.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'ppsProof',
      title: 'Proof of PPS',
      diaryCode: '2O',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 10,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'We can accept a photo or scan of a Payslip, P45, P60, tax credit statement or Medical Card.',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'identificationProof',
      title: 'Proof of Identification',
      diaryCode: '2P',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 10,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'Driving license, passport or government ID',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'CommercialDDM',
      title: 'Commercial DDM',
      diaryCode: '9C',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 11,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'We need a photo or scan of the direct debt mandate we have sent you. You will need to print, sign and date the document before returning',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'digitalJourney',
      title: 'Digital Journey',
      diaryCode: '2S',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 12,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: '',
      // info: 'Quick reminder, don\'t forget to check your email for a link from "Close Brothers". Simple follow the instruction in the email to complete your finance application if you haven\'t already done so',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'certitifateOfIncorporation',
      title: 'Certificate Of Incorporation',
      diaryCode: '2U',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 13,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'A document showing that the business has been registered',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'vlc',
      title: 'VLC',
      diaryCode: '2K',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 14,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'Copy of the Vehicle Licensing Certificate confirming you are the registered owner',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'proofOfBusinessAddress',
      title: 'Proof Of Business Address',
      diaryCode: '2Q',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 15,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'Provide a copy of ONE of the following documents addressed to the business dated within 6 months. Utility or landline telephone bill; Statement from a Bank, Building Society or Credit Union; Correspondence from the Revenue or Department of Employment Affairs. Correspondence from an insurance company in respect of an insurance policy currently in force',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'taxRegistrationCertificate',
      title: 'Tax Registration Certificate',
      diaryCode: '2V',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 16,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'Copy of Tax Registration Certificate as issued by Revenue',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
    {
      name: 'creditWorthinessCheck',
      title: 'Credit Worthiness Check',
      diaryCode: '2R',
      type: 'file',
      value: '',
      keyValues: [],
      sequence: 17,
      expanded: false,
      isSubmitted: false,
      enableSubmitButton: false,
      enableUploadButton: true,
      info: 'This is a supplementary document to your Finance Agreement for Close Brothers Premium Finance',
      uploadProgress: 0,
      files: [],
      diary: null,
      errorMessage: null,
      responseStatusText: null,
    },
  ];

  expand(doc: any) {
    // doc.expanded = !doc.expanded;

    // this.collapsed = !this.collapsed;
    if (this.expandedControl === doc.diary.id) this.expandedControl = '';
    else this.expandedControl = doc.diary.id;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onFileUploaded(document: Document) {
    // console.log('ONBOARDING PAGE RECEIVING EVENT');
    // console.log(JSON.stringify(document));

    // this.documentList = document.documentUploadedList;
    document.enableSubmitButton = document.files.length > 0;
  }

  onFileCleaned(document: any) {
    document.enableSubmitButton = document.files.length > 0;
  }

  submitDocument(document: Document) {
    document.enableSubmitButton = false;
    document.enableUploadButton = false;
    const formData = new FormData();

    for (var i = 0; i < document.files.length; i++) {
      formData.append('files', document.files[i].file);
    }

    var policyDetail = {
      id: '123',
      diaryCode: document.diaryCode,
      policyCode: 'blablabla',
      type: document.type,
      documentName: document.name,
      documentValue: 'test',
    };

    // formData.append('policyDetail', JSON.stringify(policyDetail));
    // console.log(JSON.stringify({ policyDetail: policyDetail }));

    formData.append('id', document.diary?.id ?? '');
    formData.append('diaryCode', document.diaryCode);
    formData.append('policyCode', this.data.policyCode),//this.data.policy.policyCode);
    formData.append('documentValue', document.value ? document.value : '');
    formData.append('type', document.type);
    formData.append('name', document.diary?.customerDetails?.name ?? '');
    formData.append('email', document.diary?.customerDetails?.email ?? '');

    this.openOnboardingService.uploadDocuments(formData).subscribe(
      (event: HttpEvent<object>) => {
        console.log(event);

        if (event.type === HttpEventType.Response) {
          if (event.ok === true) {
            document.isSubmitted = true;

            this.uploadDocumentEvent(document);

            document.files = [];
          } else {
            document.uploadProgress = 0;
            document.responseStatusText = event.statusText;
            document.isSubmitted = false;
          }
        } else if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(
            (event.loaded * 100) / (event.total === undefined ? 0 : event.total)
          );
          document.uploadProgress = percentDone;
        }
      },
      (error: HttpErrorResponse) => {
        document.responseStatusText = error.error.message;
        document.isSubmitted = false;
        this.uploadDocumentEvent(document);
        document.errorMessage =
          'Oops... Something went wrong, please try again in a few minutes';
      }
    );
  }

  errorMessageClosed(document: Document) {
    document.errorMessage = null;
    document.enableSubmitButton = true;
  }

  submitIban(event: Document) {}

  onTextDocumentChange(document: Document) {
    document.enableSubmitButton = document.value !== '';
  }

  onMultiTextDocumentChange(data: any) {
    data.document.enableSubmitButton = data.isFormValid;
  }

  submitTextDocument(document: Document) {
    document.enableSubmitButton = false;
    const formData = new FormData();

    let doc = <DocumentText>{
      id: document?.diary?.id ?? '',
      diaryCode: document?.diaryCode,
      documentValue: document.value ? document.value : '',
      email: document.diary?.customerDetails?.email ?? '',
      name: document.diary?.customerDetails?.name ?? '',
      policyCode: this.data.policyCode, //this.data.policy.policyCode,
      type: document.type,
      keyValues: document.keyValues,
    };

    this.openOnboardingService.uploadTextFile(doc).subscribe(
      (event: HttpEvent<object>) => {
        //   console.log(event);

        //   if (event.type === HttpEventType.Response) console.log('concluido');
        //   else if (event.type === HttpEventType.UploadProgress) {
        //     const percentDone = Math.round(
        //       (event.loaded * 100) / (event.total === undefined ? 0 : event.total)
        //     );
        //     document.uploadProgress = percentDone;

        //     if (event.loaded === event.total) {
        //       document.isSubmitted = true;
        //       this.uploadDocumentEvent(document);
        //     }
        //   }
        // },
        // (error: HttpErrorResponse) => {
        //   document.responseStatusText = error.error.message;
        //   this.uploadDocumentEvent(document);
        //   document.errorMessage =
        //     'Oops... Something went wrong, please try again in a few minutes';
        // }

        console.log(event);

        if (event.type === HttpEventType.Response) {
          if (event.ok === true) {
            document.isSubmitted = true;

            this.uploadDocumentEvent(document);

            document.files = [];
          } else {
            document.uploadProgress = 0;
            document.responseStatusText = event.statusText;
            document.isSubmitted = false;
          }
        } else if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(
            (event.loaded * 100) / (event.total === undefined ? 0 : event.total)
          );
          document.uploadProgress = percentDone;
        }
      },
      (error: HttpErrorResponse) => {
        document.responseStatusText = error.error.message;
        document.isSubmitted = false;
        this.uploadDocumentEvent(document);
        document.errorMessage =
          'Oops... Something went wrong, please try again in a few minutes';
      }
    );
  }

  uploadDocumentEvent(document: Document) {
    let submitDocumentEvent = <OnboardingPageSubmitButtonClickedEvent>{
      //OID: this.authService.getUserDetail()?.idTokenClaims?.oid,
      
      timestamp: new Date(),
      policyCode: this.data.policyCode,
      //productType: this.data.policy.productType,
      //policyStatus: this.data.policy.myChillStatus,
      title: document.title,
      diaryCode: document.diaryCode,
      id: document.diary?.id,
      type: document.type,
      documentValue: document.value,
      pagesUploaded: this.getTotalPagesUploaded(document),
      isUploaded: document.isSubmitted,
      errorMessage: document.responseStatusText,
    };

    this.eventService.SubmitDocument(submitDocumentEvent);
  }

  getTotalPagesUploaded(document: Document) {
    let documentsUploadedList = <DocumentUploaded[]>[];

    if (document.type === 'text') {
    } else {
      // documentsUploadedList = document.files.filter((x) => {
      //   <DocumentUploadedModel>{
      //     name: x.name,
      //     extension: x.extension,
      //   };
      // });

      documentsUploadedList = document.files.map((x) => {
        return <DocumentUploaded>{
          name: x.name,
          extension: x.name.split('.').pop(),
        };
      });
    }
    return documentsUploadedList;
  }

  landingPageEvent() {
    let diaryListEvent = <OnboardingPageLandingEvent>{
      //OID: this.authService.getUserDetail()?.idTokenClaims?.oid,
      utm_campaign: this.data.utm_campaign,
      utm_source: this.data.utm_source,
      utm_medium: this.data.utm_medium,
      policyCode: this.data.policyCode,
      timestamp: new Date(),
      diaries: this.diaries,
    };

    this.eventService.onboardingLandingPageEvent(diaryListEvent);
  }
}
