import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Document } from '../../../../models/document.model';

@Component({
  selector: 'app-generic-document-capture',
  templateUrl: './generic-document-capture.component.html',
  styleUrl: './generic-document-capture.component.scss'
})
export class GenericDocumentCaptureComponent {
  @Input() fileTitle?: string;
  @Input() instructions?: string[];
  @Input() document!: Document;
  @Input() enableSubmitButton: boolean = false;
  @Input() enableUploadButton: boolean = false;

  @Input() allowMultipleFiles: boolean = true;

  @Output() onFileUploaded = new EventEmitter();
  @Output() onCleaned = new EventEmitter();
  @Output() onSubmitted = new EventEmitter();

  onUploadDocument(event: any) {
    this.onFileUploaded.emit(event);
  }

  onCleanDocument(event: any) {
    this.onCleaned.emit(event);
  }

  onSubmit(event: any) {
    console.log('submitted in generic document capture component');
    this.onSubmitted.emit(event);
  }
}
