import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { OnboardingPageLandingEvent } from '../models/events/onboardingPageLandingEvent.model';
import { environment } from '../../environments/environment';
import { OnboardingPageSubmitButtonClickedEvent } from '../models/events/onboardingPageSubmitButtonClickedEvent.model';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private httpClient = inject(HttpClient)

 
  onboardingLandingPageEvent(landingPageEvent: OnboardingPageLandingEvent) {
    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    this.httpClient
      .post(
        `${environment.apiManagement.address}newonboardingpageviewevent`,
        landingPageEvent,
        { headers: customHeaders }
      )
      .subscribe(
        (res) => {
          console.log(res);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  SubmitDocument(
    uploadDocumentEvent: OnboardingPageSubmitButtonClickedEvent
  ): void {
    const customHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Ocp-Apim-Subscription-Key',
        environment.apiManagement.subscriptionKey
      );

    this.httpClient
      .post(
        `${environment.apiManagement.address}newuploaddocumentevent`,
        uploadDocumentEvent,
        { headers: customHeaders }
      )
      .subscribe(
        (res) => {
          console.log(res);
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
