{
  "name": "chill.my-chill.onboarding.angular",
  "version": "2.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --output-hashing=all",
    "build:dev": "ng build --configuration=development --output-hashing=all",
    "build:uat": "ng build --configuration=staging --output-hashing=all",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:Chill.MyChill.Onboarding.Angular": "node dist/chill.my-chill.onboarding.angular/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/material": "^17.3.10",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/platform-server": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@angular/ssr": "^17.3.8",
    "@microsoft/applicationinsights-web": "^3.3.2",
    "express": "^4.18.2",
    "ngx-iban-validator": "^1.2.2",
    "ngx-mask": "^18.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.0",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.7",
    "@types/node": "^18.18.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
