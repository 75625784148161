import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-confirmation',
  templateUrl: './upload-confirmation.component.html',
  styleUrl: './upload-confirmation.component.scss'
})
export class UploadConfirmationComponent {

}
