<div class="generic-document-capture-container">
    <app-drag-and-drop
      [allowMultipleFiles]="allowMultipleFiles"
      [enableUploadButton]="enableUploadButton"
      [fileTitle]="fileTitle"
      (onFileUploaded)="onUploadDocument($event)"
      (onClean)="onCleanDocument($event)"
    ></app-drag-and-drop>
    <div id="instruction-container" *ngIf="instructions">
      <strong>Just make sure</strong><br />
      <ul>
        <li
          *ngFor="let instruction of instructions"
          [innerHTML]="instruction"
        ></li>
        <br />
      </ul>
    </div>
    <div class="buttons">
      <button
        class="submit"
        [disabled]="!enableSubmitButton"
        (click)="onSubmit($event)"
      >
        Submit
      </button>
    </div>
  </div>
  