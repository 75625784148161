import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import {Document} from '../../../../models/document.model'

@Component({
  selector: 'app-irish-registration-capture',
  templateUrl: './irish-registration-capture.component.html',
  styleUrl: './irish-registration-capture.component.scss'
})
export class IrishRegistrationCaptureComponent {
  @Input() progress: number = 0;
  @Input() document!: Document;

  @Output() onChanged = new EventEmitter();
  @Output() onSubmitted = new EventEmitter();
  @Output() onErrorMessageClosed = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  irishRegistrationForm = this.fb.group({
    registrationNumber: [null, Validators.required],
  });

  onChange() {
    (this.document.value =
      this.irishRegistrationForm.controls.registrationNumber.value),
      this.document.keyValues?.push({
        key: 'irishRegistration',
        value:
          this.irishRegistrationForm.controls.registrationNumber.value ?? '',
      });

    this.onChanged.emit(this.document);
  }

  onSubmit() {
    this.onSubmitted.emit(this.document);
  }

  closeErrorMessage() {
    this.onErrorMessageClosed.emit(this.document);
  }
}
