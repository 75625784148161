import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Document } from '../../../../models/document.model'

@Component({
  selector: 'app-proposal-form-capture',
  templateUrl: './proposal-form-capture.component.html',
  styleUrl: './proposal-form-capture.component.scss'
})
export class ProposalFormCaptureComponent {
  @Input() progress: number = 0;
  @Input() document!: Document;

  @Output() onSubmitted = new EventEmitter();
  @Output() onFileUploaded = new EventEmitter();
  @Output() onFileCleaned = new EventEmitter();
  @Output() onErrorMessageClosed = new EventEmitter();

  instructions: string[] = [
    'We can make out all of the information.',
    'We can see all four corners of the bonus in the image.',
    'All parts of the form are filled out and that the information is correct.',
    'The form is signed and dated.',
    'Only files with pdf, jpeg, jpg and png extensions are accepted.',
  ];
  onUploadDocument(event: any) {
    this.document.files = event.documentUploadedList;
    // this.checkSubmitButtonState();

    this.onFileUploaded.emit(this.document);
  }
  onCleanDocument(event: any) {
    this.document.files = event.documentUploadedList;
    // this.checkSubmitButtonState();
    this.onFileCleaned.emit(this.document);
  }
  onSubmittedDocument(event: any) {
    this.onSubmitted.emit(this.document);
  }

  closeErrorMessage() {
    this.onErrorMessageClosed.emit(this.document);
  }
}
