import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CommonModule,DatePipe } from '@angular/common';
import { OnboardingModule } from './modules/onboarding/onboarding.module';
import { HomeModule } from './modules/home/home.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';
import { FramePageComponent } from './components/frame-page/frame-page.component';
import { BadRequestPageComponent } from './components/bad-request-page/bad-request-page.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { MyMonitoringService } from './services/logging.service';
import { ErrorHandlerService } from './services/errorHandlerService.service';

@NgModule({
  declarations: [
    AppComponent,
    FramePageComponent,
    BadRequestPageComponent,
    NotFoundPageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgxMaskDirective
  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync(),
    provideNgxMask(),
    MyMonitoringService,
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
