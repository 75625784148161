import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './modules/home/pages/home-page/home-page.component';
import { FramePageComponent } from './components/frame-page/frame-page.component';
import { BadRequestPageComponent } from './components/bad-request-page/bad-request-page.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';

const routes: Routes = [
  {
    path: "",
    component: FramePageComponent,
    children: [
     
     
      {
        path: "pc/:policyCode",
        component: HomePageComponent
      }
    ]
  },
  {
    path: "400",
    component: BadRequestPageComponent
  },
  {
    path: "404",
    component: NotFoundPageComponent
  },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
